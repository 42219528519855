exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-faculty-staff-and-board-mdx": () => import("./../../../src/pages/about/faculty-staff-and-board.mdx" /* webpackChunkName: "component---src-pages-about-faculty-staff-and-board-mdx" */),
  "component---src-pages-about-fellows-frances-olympic-gamble-credits-mdx": () => import("./../../../src/pages/about/fellows/frances-olympic-gamble-credits.mdx" /* webpackChunkName: "component---src-pages-about-fellows-frances-olympic-gamble-credits-mdx" */),
  "component---src-pages-about-fellows-index-mdx": () => import("./../../../src/pages/about/fellows/index.mdx" /* webpackChunkName: "component---src-pages-about-fellows-index-mdx" */),
  "component---src-pages-about-fellows-land-project-credits-mdx": () => import("./../../../src/pages/about/fellows/land-project-credits.mdx" /* webpackChunkName: "component---src-pages-about-fellows-land-project-credits-mdx" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-ethics-handbook-mdx": () => import("./../../../src/pages/ethics-handbook.mdx" /* webpackChunkName: "component---src-pages-ethics-handbook-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-rice-mdx": () => import("./../../../src/pages/projects/rice.mdx" /* webpackChunkName: "component---src-pages-projects-rice-mdx" */),
  "component---src-pages-support-the-grp-mdx": () => import("./../../../src/pages/support-the-grp.mdx" /* webpackChunkName: "component---src-pages-support-the-grp-mdx" */),
  "component---src-pages-syllabus-mdx": () => import("./../../../src/pages/syllabus.mdx" /* webpackChunkName: "component---src-pages-syllabus-mdx" */)
}

